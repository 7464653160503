import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"
import InfoModal from "../../../components/info-modal"

import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import PanoContainer from "../../../components/pano-container"
import { Howl } from "howler"
import QuestionModal from "../../../components/question-modal"
import { Vector3 } from "three"
import ToolBar from "../../../components/tool-bar"

// Custom Icons
import QuestionMark from "../../../../static/icons/question-mark-white.png"
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"
import EmergencyMark from "../../../../static/icons/emergency-mark-white.png"

export default class TheSpotlight extends React.Component {
  constructor() {
    super()
    this.state = {
      currentQuestion: "",
      infoModalIsOpen: false,
      questionModalIsOpen: false,
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  createQuestionInfoSpot(question, position) {
    const questionInfoSpot = new Infospot(300, QuestionMark, false)
    questionInfoSpot.position.set(position.x, position.y, position.z)
    questionInfoSpot.addHoverText(question)
    questionInfoSpot.addEventListener("click", () => {
      this.setState({
        currentQuestion: question,
        questionModalIsOpen: true,
      })
    })

    return questionInfoSpot
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama(
      "/departments/the-spotlight/panorama.png"
    )
    const stagePanorama = new ImagePanorama(
      "/departments/the-spotlight/stage-panorama.png"
    )
    const chairPanorama = new ImagePanorama(
      "/departments/the-spotlight/chair-panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    stagePanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    chairPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    mainPanorama.setLinkingImage(ArrowMark, 350)
    chairPanorama.setLinkingImage(ArrowMark, 350)
    stagePanorama.setLinkingImage(ArrowMark, 350)

    // Linking Panorama's
    mainPanorama.link(chairPanorama, new Vector3(4849.35, -909.07, 746.76))
    chairPanorama.link(mainPanorama, new Vector3(4944.81, 45.67, 686.73))

    mainPanorama.link(stagePanorama, new Vector3(-410.55, -731.85, -4922.88))
    stagePanorama.link(mainPanorama, new Vector3(-219.07, 127.92, 4983.49))

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(-4790.41, -372.64, 1357.49)
    doorInfoSpot.addHoverText("Go to the next department")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/the-presents/")
    })

    const emergencyExitSpot = new Infospot(350, EmergencyMark, false)
    emergencyExitSpot.position.set(-3109.28, 1943.74, 3394.84)
    emergencyExitSpot.addHoverText("Go to the emergency exit")
    emergencyExitSpot.addEventListener("click", () => {
      navigate("/departments/the-emergency-exit/")
    })

    // Question Marks
    const spotlightQuestion = this.createQuestionInfoSpot(
      "Does it make sense to be in the spotlight on my birthday?",
      new Vector3(-3711.57, -1034.54, -3173.44)
    )

    const comfortableQuestion = this.createQuestionInfoSpot(
      "Do I feel comfortable in the spotlight?",
      new Vector3(1533.2, 4758.59, -3.07)
    )

    const blowingOutCandlesQuestion = this.createQuestionInfoSpot(
      "Blowing out the candles is pretty stressful",
      new Vector3(2221.79, -4336.2, 1099.38)
    )

    const celebrateOthersQuestion = this.createQuestionInfoSpot(
      "I like to celebrate others",
      new Vector3(2302.72, -3241.11, 3027.09)
    )

    const attentionQuestion = this.createQuestionInfoSpot(
      "I wish I was the center of the attention",
      new Vector3(354.68, -957.73, -4884.93)
    )

    const spotlightNaturalQuestion = this.createQuestionInfoSpot(
      "Being in the spotlight doesn't come natural to me",
      new Vector3(2544.11, 2912.59, 3160.36)
    )

    const surprisePartyQuestion = this.createQuestionInfoSpot(
      "I wish someone throws me a surprise party",
      new Vector3(463.23, -4346.13, -2418.34)
    )

    // Add the InfoSpots to the Main Panorama
    mainPanorama.add(spotlightQuestion)
    mainPanorama.add(comfortableQuestion)
    mainPanorama.add(blowingOutCandlesQuestion)

    chairPanorama.add(celebrateOthersQuestion)
    chairPanorama.add(attentionQuestion)
    chairPanorama.add(doorInfoSpot)
    chairPanorama.add(emergencyExitSpot)

    stagePanorama.add(spotlightNaturalQuestion)
    stagePanorama.add(surprisePartyQuestion)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    // Set initial Camera position
    this.setPositionToTarget(new Vector3(4984.29, -346.24, 40.15), viewer)

    viewer.add(mainPanorama)
    viewer.add(chairPanorama)
    viewer.add(stagePanorama)

    // Setup the new Howl.
    this.sound = new Howl({
      src: ["/departments/the-spotlight/the-spotlight.mp3"],
    })

    // Play the sound.
    this.sound.play()

    // Fade In
    this.sound.fade(0.0, 0.5, 2000)
  }

  componentWillUnmount() {
    this.sound.fade(0.5, 0.0, 1000)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
    })
  }

  closeQuestionModal = () => {
    this.setState({
      questionModalIsOpen: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="The Spotlight" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <ToolBar
          onInfoClick={() =>
            this.setState({
              infoModalIsOpen: true,
            })
          }
          muteSound={() => this.sound.mute(!this.sound.mute())}
        />
        <LoadingBar progress={this.state.progress} />
        <InfoModal
          isOpen={this.state.infoModalIsOpen}
          onRequestClose={this.closeInfoModal}
          title="The Spotlight"
        >
          <p>
            A bit more light please!! No, no, no that’s enough. How to find the
            balance in not being seen ver- sus being blinded by attention?
          </p>
          <p>
            Part of the birthday’s unavoidability, is the tendency of the social
            environment to put the birth- day-person in the limelight. Whether
            you, as birthday celebrant, like that or not can be taken into
            account. However, according to social norms, it’s widely accepted
            that you just have to deal with having more eyes on you than the
            average day. Hmmm, isn’t that exciting?
          </p>
          <p>
            On top of that, there is also the surprise effect that many people
            in the surroundings like to mix with the limelight. To make you lose
            control (a bit). But remember, it’s all out of love.
          </p>
          <p>
            Still feeling comfortable? Or are you searching for the emergency
            exit already? How much audience can you handle on your birthday? And
            how many empty seats?
          </p>
        </InfoModal>
        <QuestionModal
          isOpen={this.state.questionModalIsOpen}
          onRequestClose={this.closeQuestionModal}
          question={this.state.currentQuestion}
        ></QuestionModal>
      </>
    )
  }
}
